import React from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';
import styles from './option-item.module.scss';

const OptionItem = (props) => {
  const { label, onClick, unselectable } = props;

  const sortItemClass = cn({
    [styles.container]: true,
    [styles.unselectable]: unselectable
  });

  return (
    <li className={sortItemClass}>
      <button
        type="button"
        onClick={onClick}
      >
        <span>{label}</span>
      </button>
    </li>
  );
};

OptionItem.propTypes = {
  label: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  unselectable: PropTypes.bool
};

OptionItem.defaultProps = {
  unselectable: false,
  onClick: () => {}
};

export default OptionItem;
